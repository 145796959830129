
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import InformationDDC from "./InformationDDC.vue";
import formatUtil from "../../mixins/roundValues";
import highlightReports from "../../mixins/highlightReports";
import { formatFinancialAmounts, formatTwoDecimal} from '../../utils/format'

export default Vue.extend({
  name: "Care",
  components: { InformationDDC },
  mixins: [formatUtil, highlightReports],
  data: () => ({
    data: [],
    isLoading: false,
    payload: {
      year: null,
    },
  }),
  computed: {
    maxtableHeight() {
      return window.screen.height - window.screen.height * 0.20;
    },
    title() {
      return i18n.t("reports.ddcreports.listReports.care");
    },
    headers() {
      return [
        {
          text: i18n.t("reports.ddcreports.care.n"),
          value: "n",
        },
        {
          text: i18n.t("reports.ddcreports.care.country"),
          value: "country",
        },
        {
          text: i18n.t("reports.ddcreports.care.region"),
          value: "region",
        },
        {
          text: i18n.t("reports.ddcreports.care.surveyYear"),
          value: "surveyYear",
        },
        {
          text: i18n.t("reports.ddcreports.care.dataYear"),
          value: "dataYear",
        },
        {
          text: i18n.t("reports.ddcreports.care.previousSurveyYear"),
          value: "previousSurveyYear",
        },
        {
          text: i18n.t("reports.ddcreports.care.previousDataYear"),
          value: "previousDataYear",
        },
        {
          text: i18n.t("reports.ddcreports.care.current_HTCs_Total"),
          value: "current_HTCs_Total",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.previous_HTCs_Total"),
          value: "previous_HTCs_Total",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.htCs_Difference"),
          value: "htCs_Difference",
        },
        {
          text: i18n.t("reports.ddcreports.care.htCs_Percent_Change"),
          value: "htCs_Percent_Change",
        },
        {
          text: i18n.t("reports.ddcreports.care.current_HCCs_Total"),
          value: "current_HCCs_Total",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.previous_HCCs_Total"),
          value: "previous_HCCs_Total",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.hcCs_Difference"),
          value: "hcCs_Difference",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.hcCs_Percent_Change"),
          value: "hcCs_Percent_Change",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.current_LT_18_Prophy_Percent"),
          value: "current_LT_18_Prophy_Percent",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.previous_LT_18_Prophy_Percent"),
          value: "previous_LT_18_Prophy_Percent",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.care.lT_18_Prophy_Percent_Difference"
          ),
          value: "lT_18_Prophy_Percent_Difference",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.current_GE_18_Prophy_Percent"),
          value: "current_GE_18_Prophy_Percent",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.previous_GE_18_Prophy_Percent"),
          value: "previous_GE_18_Prophy_Percent",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.care.gE_18_Prophy_Percent_Difference"
          ),
          value: "gE_18_Prophy_Percent_Difference",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.current_Inh_Patients_ITI"),
          value: "current_Inh_Patients_ITI",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.previous_Inh_Patients_ITI"),
          value: "previous_Inh_Patients_ITI",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.inh_Patients_ITI_Difference"),
          value: "inh_Patients_ITI_Difference",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.care.inh_Patients_ITI_Count_Perc_Diff"
          ),
          value: "inh_Patients_ITI_Count_Perc_Diff",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.current_New_Inh_Patients_ITI"),
          value: "current_New_Inh_Patients_ITI",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.care.previous_New_Inh_Patients_ITI"),
          value: "previous_New_Inh_Patients_ITI",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.care.new_Inh_Patients_ITI_Difference"
          ),
          value: "new_Inh_Patients_ITI_Count_Perc_Diff",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },
      ];
    },
    yearSelected() {
      return this.$store.getters["ddcreport/getYearSelected"].year;
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.payload.year = this.yearSelected;
      axios
        .get("report/DDC/Care-Breakdown", {
          params: { year: this.yearSelected },
        })
        .then((res: any) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(`report/ddc/Care-Breakdown/export`, {
          params: { year: this.yearSelected },
          responseType: "blob",
        })
        .then((res) => {
          const fileName =
            `${i18n.t("reports.ddcreports.pagetitle")} -` + this.title;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.yearSelected) {
      this.getData();
    }
  },
  watch: {
    yearSelected() {
      this.getData();
    },
  },
});
